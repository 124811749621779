
import { Options, Vue } from "vue-class-component";
import { TypeUserListEnum } from "../types/enum/type-user-item";
import UserCard from "../components/UserCard.vue";
import { UserListType } from "../types/user/user-list-type";
import { userListAllHandler } from "../handlers/user/user-account-handlers";
import { isLoggedIn } from "../handlers/handlers-helpers";

@Options({
  components: {
    UserCard,
  },
})
export default class UserManagement extends Vue {
  private type_user_management: TypeUserListEnum = TypeUserListEnum.MANAGEMENT;

  private student_list: Array<UserListType> = [];

  mounted(): void {
    if (isLoggedIn()) {
      userListAllHandler()
        .then((response_course_list_taught: Array<UserListType> | void) => {
          this.student_list = response_course_list_taught || [];
        })
        .catch((error) => {
          console.error(error);
        });
    }
  }
}
